// import React, { useState, useEffect, ComponentType } from "react"
// import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
// import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// // Learn more: https://www.framer.com/docs/guides/overrides/

// export function videoSwicther(Component: ComponentType): ComponentType {
//     const WithVideoSwitcher: ComponentType = (props: any) => {
//         const [src, setSrc] = useState("")

//         useEffect(() => {
//             const handleStorageChange = () => {
//                 const storedToggleState =
//                     localStorage.getItem("currentToggleState")
//                 const newSrc =
//                     storedToggleState === "dark"
//                         ? "https://framerusercontent.com/assets/HnKvJ9E8dxSBdE4M9D3mra8GDBo.mp4"
//                         : "https://framerusercontent.com/assets/m2IiDg0TvPy6MKOPo4HT5kmw4.mp4"
//                 setSrc(newSrc)
//             }

//             handleStorageChange()
//             window.addEventListener("storage", handleStorageChange)

//             return () => {
//                 window.removeEventListener("storage", handleStorageChange)
//             }
//         }, [])

//         return <Component {...props} url={src} background="white" />
//     }

//     return WithVideoSwitcher
// }
